






import { Chatbox, Session } from "@talkjs/react";

import { useCallback, useRef } from "react";
import { Inbox } from "@talkjs/react";
import Talk from "talkjs";









import {useContext, useEffect} from 'react';


import { PageContext } from '../MyContext';




import "../styles/profile.css";
import SideNav from "../components/SideNav";
import TopNav from "../components/TopNav";






const Chat = () => {


const {thisPage, setThisPage} = useContext(PageContext);



useEffect(() => {
  
  setThisPage('chat');


}, [])









const chatbox = {
  messageFilter: ["any", [ // The "any" filter matches messages that match any of the filters within it.
      



  


  {custom: {visibleTo: "!exists"}}, 




                    




      {custom: {visibleTo: ["==", localStorage.getItem('userId')]}} // A filter that matches messages where the custom value "visibleTo" matches the user ID.
  ]]
}













  return (
    <div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
      <SideNav />

      <div class="flex-grow-1 overflow-y-lg-auto">
        <TopNav />

        <main class=" bg-white pt-3">
          <div class="container-fluid p-0" >




                    


              


    <Session appId="y0bIIGuS" userId={localStorage.getItem("userId")}>

      <Inbox
        

            
              
        loadingComponent={<h4 style={{display:'flex', alignItems:'center', justifyContent:'center', paddingTop:'5rem'}}>Loading...</h4>}
              
              

          messageFilter={chatbox.messageFilter}


              



        style={{             width:'100%'}}
        
        className="chat-container"
      />
    </Session>











          </div>
        </main>
      </div>
    </div>
  );
};

export default Chat;


