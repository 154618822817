import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBwxyosx7GnNGVYxeZyHhJH-KNjo3-UQfA",
  authDomain: "instaxpert.firebaseapp.com",
  projectId: "instaxpert",
  storageBucket: "instaxpert.appspot.com",
  messagingSenderId: "851631088009",
  appId: "1:851631088009:web:f13854e2aeef0dea4c4158"
};
const app = initializeApp(firebaseConfig);

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();
const facebook = new FacebookAuthProvider();
  
// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({   
    prompt : "select_account "
});
export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const signInWithFacebookPopup = () => signInWithPopup(auth, facebook);


